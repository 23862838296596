<template>
  <div
    class="
      page-container
      w-screen
      h-vh-9/10
      relative
      flex flex-wrap
      justify-center
    "
  >
    <div
      class="container flex flex-1 justify-center flex-wrap"
    >
      <div class="w-full my-5 text-2xl text-center text-primary">
        
        <h1 style="text-transform:uppercase;">Datos de Facturación</h1>
        <h1 style="font-size:15px;" class="">Enviar los siguientes datos al correo: correo@gmail.com</h1>
        <ul style="color:black; font-size: 15px; text-align:left; margin-left: 20%;">
            <li>» Nombre Completo</li>
            <li>» Identificación Oficial Vigente Escaneada por ambos lados.</li>
            <li>» Rfc</li>
            <li>» Homoclave</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>